<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <Confirm ref="confirm"/>
      <transition name="slide">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-3"><h4>Registro de notas de: </h4></div>
              <div class="col">
                <form class="form-inline float-right">
                  <input id="user" type="hidden" value=""/>
                  <b>Filas por p&aacute;gina</b>
                  <select id="filasPorPagina" class="form-control mr-2">
                    <option>Todos</option>
                  </select>

                  <button class="btn btn-outline-dark mr-2" type="button"
                          @click="borrarFiltroBtnOnClick()">
                    <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                    Quitar Filtro
                  </button>
                </form>
              </div>
            </div>
            <hr clear="all"/>
            <div class="row">
              <div class="col-4">
              </div>
              <div class="col-1">
                <label class="form-label" for="gestion"><strong>Gestión:</strong></label>
              </div>
              <div class="col-3">
                <select class="form-control pl-1 pr-1" id="gestion"
                        v-model="gestion" required="required"
                        @change="actualizarDatosGrid();listarParametros(gestion)"
                >
                  <option value="" selected>:: SELECCIONAR ::</option>
                  <template v-for="(option,key) in gestiones">
                    <optgroup :label="key">
                      <option v-for="suboption in option" :value="suboption.value">
                        {{ suboption.label }}
                      </option>
                    </optgroup>
                  </template>
                </select>
              </div>
              <div class="col-4">
              </div>
            </div>
          </div>
          <div class="card-body pt-0 pr-1 pb-0 pl-0">
            <JqxGrid
                ref="gridSystem" :altrows="true"
                :columns="columns" :filterable="true"
                :height="'500px'" :showfilterrow="true"
                :sortable="true" :source="dataAdapter"
                :groupable="true" :groups="['carrera']"
                :theme="'bootstrap'" :width="'100%'"
                :localization="localization" :groupsexpandedbydefault="true"
                :columnsresize="true"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import axios from "axios";
import Alerts from "@/views/notifications/Alerts";
import Confirm from "@/views/notifications/Confirm";
import Toast from "@/views/notifications/Toast";

var sourceLst;
var address;
var modalPdf;

var btnNotas = function (sede_id, gestion, carrera_id, plan_id, materia_id, grupo, parcial, nombre_parcial, estado, estado_anterior) {
  let html = ''
  let clase = ''
  let grabar = ''
  if (estado == 'registrado') {
    html = '<span class="text-white mr-1"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle-check fa-xl"><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" class=""></path></svg></span>';
    clase = 'btn-success'
    grabar = 'Registrado'
  } else if (estado == 'detro de plazo') {
    html = '<span class="text-white mr-1"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle-check fa-xl"><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" class=""></path></svg></span>';
    clase = 'btn-warning'
    grabar = 'Registrar'
  } else {
    html = '';
    clase = 'btn-danger'
    grabar = 'Fuera de plazo'
  }
  return (estado == 'detro de plazo' ?
          '<a class="btn btn-sm btn-block ml-1 ' + clase + ' " href="/#/docenteregistronotas/' + sede_id + '/' + gestion + '/' + carrera_id + '/' + plan_id + '/' + materia_id + '/' + grupo + '/' + parcial + '/parcialnuevo" title="' + grabar + ' notas del ' + nombre_parcial + '">' +html+ grabar + '</a>' :
          (estado == 'registrado' ?"<span class='btn btn-sm btn-block ml-1 "+clase+"'>"+html+grabar+"</span>":"<small class='btn btn-sm btn-block ml-1 "+clase+"'>"+html+grabar+"</small>"));
}

export default {
  name: 'Docentes',
  components: {
    Toast,
    Confirm,
    Alerts,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      items: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      gestion: '',
      carrera_id: 0,
      plan_id: 0,
      gestiones: [],
      carreras: [],
      planes: [],
      parametros: {
        grupo: 'rangonotas',
        nota_minima: 35,
        nota_aprobacion_pregrado: 51,
        nota_aprobacion_postgrado: 65,
        nota_maxima_parcial1: 25,
        nota_maxima_parcial2: 25,
        nota_maxima_parcial3: 25,
        nota_maxima_examenfinal: 25,
        nota_maxima: 100
      },
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      columns: [
        {
          text: 'carrera', datafield: "carrera", hidden: true,
        },
        {
          text: 'Semestre', datafield: "semestre", width: '6%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Semestre</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por Gestión"});
          }
        },
        {
          text: 'Materia', datafield: "materia", width: '32%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Materia</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por Materia"});
          }
        },
        {
          text: 'Grupo', datafield: "grupo", width: '7%', cellsalign: 'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "grupo"});
          }
        },
        {
          text: '1er Parcial',
          datafield: "parcial1",
          width: '11%',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return btnNotas(localStorage.sedeSeleccionada, modalPdf.gestion, rowdata.carrera_id, rowdata.plan_id, rowdata.materia_id, rowdata.grupo, '1', 'primer parcial', rowdata.parcial1, '')
          },
          filterable: false,
          sortable: false
        },
        {
          text: '2do Parcial',
          datafield: "parcial2",
          width: '11%',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return btnNotas(localStorage.sedeSeleccionada, modalPdf.gestion, rowdata.carrera_id, rowdata.plan_id, rowdata.materia_id, rowdata.grupo, '2', 'segundo parcial', rowdata.parcial2, rowdata.parcial1)
          },
          filterable: false,
          sortable: false
        },
        {
          text: '3er Parcial',
          datafield: "parcial3",
          width: '11%',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return btnNotas(localStorage.sedeSeleccionada, modalPdf.gestion, rowdata.carrera_id, rowdata.plan_id, rowdata.materia_id, rowdata.grupo, '3', 'tercer parcial', rowdata.parcial3, rowdata.parcial2)
          },
          filterable: false,
          sortable: false
        },
        {
          text: 'Examen Final',
          datafield: "examen_final",
          width: '11%',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return btnNotas(localStorage.sedeSeleccionada, modalPdf.gestion, rowdata.carrera_id, rowdata.plan_id, rowdata.materia_id, rowdata.grupo, 'examen_final', 'Examen Final', rowdata.examen_final, rowdata.parcial3)
          },
          filterable: false,
          sortable: false
        },
        {
          text: 'Seg. Instancia',
          datafield: "seg_instancia",
          width: '11%',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return btnNotas(localStorage.sedeSeleccionada, modalPdf.gestion, rowdata.carrera_id, rowdata.plan_id, rowdata.materia_id, rowdata.grupo, 'seg_instancia', 'Segunda Instancia', rowdata.seg_instancia, rowdata.examen_final)
          },
          filterable: false,
          sortable: false
        },
      ],
      localization: {
        'emptydatastring': "No existen datos",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + this.gestion + '/listarpordocente?token=' + localStorage.getItem("api_token");
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'carrera_id', type: "int"},
        {name: 'carrera', type: "string"},
        {name: 'plan_id', type: "int"},
        {name: 'materia_id', type: "int"},
        {name: 'semestre', type: "int"},
        {name: 'materia', type: "string"},
        {name: 'usuario', type: "string"},
        {name: 'grupo', type: "string"},
        {name: 'parcial1', type: "string"},
        {name: 'parcial2', type: "string"},
        {name: 'parcial3', type: "string"},
        {name: 'examen_final', type: "string"},
        {name: 'seg_instancia', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'materia',
      sortdirection: 'asc',
      cache: false,
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf('admin') >= 0) {
          return true
        }
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        } else {
          return false;
        }
      }
    },
    excelBtnOnClick: function () {
      // this.$refs.gridSystem.hidecolumn('documentos');
      // this.$refs.gridSystem.hidecolumn('editar');
      // this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'MateriaNotas' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      // this.$refs.gridSystem.showcolumn('documentos');
      // this.$refs.gridSystem.showcolumn('editar');
      // this.$refs.gridSystem.showcolumn('eliminar');
    },
    nuevoBtnOnClick: function () {
      this.$router.push({path: '/docentemateria/' + this.$route.params.id + '/nuevo'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    eliminar: function (docenteid, id) {
      modalPdf.$refs.confirm('', 'Eliminar este registro', function (docenteid, id) {
        this.$refs.gridSystem.clearfilters();
      })
    },
    actualizarDatosGrid: function (event) {
      if (modalPdf.gestion != '' && parseInt(modalPdf.carrera_id) >= 0) {
        sourceLst.url = modalPdf.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + modalPdf.gestion + '/listarpordocente?token=' + localStorage.getItem("api_token");
        modalPdf.$refs.gridSystem.clearfilters();
      }
    },
    listarParametros: function (gestion) {
      //axios.get(this.$apiAdress + '/api/parametros/' + localStorage.sedeSeleccionada + '/' + grupo + '/listar?token=' + localStorage.getItem("api_token"))
      axios.get(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + gestion + '/listarrangonotas?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.parametros = response.data;
            if(modalPdf.parametros.nota_maxima_parcial1<=0){
              modalPdf.$refs.gridSystem.hidecolumn('parcial1');
            }else{
              modalPdf.$refs.gridSystem.showcolumn('parcial1');
            }
            if(modalPdf.parametros.nota_maxima_parcial2<=0){
              modalPdf.$refs.gridSystem.hidecolumn('parcial2');
            }else{
              modalPdf.$refs.gridSystem.showcolumn('parcial2');
            }
            if(modalPdf.parametros.nota_maxima_parcial3<=0){
              modalPdf.$refs.gridSystem.hidecolumn('parcial3');
            }else{
              modalPdf.$refs.gridSystem.showcolumn('parcial3');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    grabarParametros: function (event) {
      if (!modalPdf.$refs.frmParametros.checkValidity()) {
        modalPdf.$refs.frmParametros.classList.add('was-validated');
      } else {
        modalPdf.modalConfig = false;
        modalPdf.$refs.alert.show('Grabando materias asignadas');
        axios.post(this.$apiAdress + '/api/parametros/' + localStorage.sedeSeleccionada + '/update?token=' + localStorage.getItem("api_token"),
            modalPdf.parametros
        )
            .then(function (response) {
              modalPdf.$refs.mensajeToast.makeToast('', 'Parámetros actualizados satisfactoriamente.', 'success',);
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
              modalPdf.$refs.alert.hide();
              modalPdf.modalConfig = true;
            });
      }
    }
  },
  computed: {},
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    axios.get(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/gestionesdocente?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.gestiones = response.data.gestiones;
          if (typeof modalPdf.$route.query.gestion === 'undefined' || modalPdf.$route.query.gestion === null) {
            modalPdf.gestion = response.data.mesActual;
          } else {
            modalPdf.gestion = modalPdf.$route.query.gestion
          }
          if (typeof modalPdf.$route.query.carrera_id === 'undefined' || modalPdf.$route.query.carrera_id === null) {
            modalPdf.carrera_id = "0";
          } else {
            modalPdf.carrera_id = modalPdf.$route.query.carrera_id;
          }
          modalPdf.actualizarDatosGrid();
          modalPdf.listarParametros(modalPdf.gestion)
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  },
}
</script>